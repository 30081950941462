<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <grafico-frequencia-entrada-municipio
          :placa="placa"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <grafico-frequencia-saida-municipio
          :placa="placa"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {

    components: {
      GraficoFrequenciaEntradaMunicipio: () => import('@/components/chart/perfil-veiculo/GraficoFrequenciaEntradaMunicipio'),
      GraficoFrequenciaSaidaMunicipio: () => import('@/components/chart/perfil-veiculo/GraficoFrequenciaSaidaMunicipio'),
    },

    props: {
      placa: {
        type: String,
        default: '',
      },
    },

    data () {
      return { }
    },

  }
</script>
